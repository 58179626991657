import { QuestionResponse} from "api/saq/types";
import { qmService } from "api/services";
import { QMIdentifiers, QMUrls } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';

const fetchQuestions = ({
    queryKey
}: {
    queryKey: string[];
}): Promise<QuestionResponse> => {
    const [, questionnaireId] = queryKey;
    return qmService.get(QMUrls.question(questionnaireId));
};

export const useQMQuestions = (questionnaireId: string) =>
    useQuery<QuestionResponse, Error, QuestionResponse, string[]>(
        [QMIdentifiers.question, questionnaireId],
        fetchQuestions,
        { enabled: !!questionnaireId }
    );
